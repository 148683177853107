(function(window, document, $, __tnt) {

    var me = __tnt.eedition || (__tnt.eedition = {});

    /* Private */
    var eeData = $('*[data-eedition]').data('eedition'),
        pageUuid = $('*[data-eedition]').data('eedition-uuid'),
        pageMargin = ($('*[data-eedition]').data('eedition-margin')) ? true : false,
        tapDefined = false,
        aspectRatio = $('.image-plain').data('image-width') / $('.image-plain').data('image-height');

    /* Public */
    me.iscroll = {

        init: function() {

            // Default scroller options 
            var segOptions = {
                HWCompositing: false,
                momentum: false,
                doubleTap: true,
                freeScroll: true,
                mouseWheel: true,
                scrollX: true,
                scrollY: true,
                scrollerMargin: pageMargin,
                tap: true,
                zoom: true,
                zoomMax: 25,
                keyBindings: {
                    pageUp: 33,
                    pageDown: 34,
                    end: 35,
                    home: 36,
                    left: 37,
                    up: 38,
                    right: 39,
                    down: 40
                }
            },
            pageOptions = {
                scrollX: true,
                scrollY: false,
                tap: true
            }

            if ($('#eedition-segments-container').length > 0) {

                var $segScroller = $('#eedition-segments-container .eedition-scroller');
                var pageSizingHeight = $('#eedition-wrapper').hasClass('page-sizing-height');

                // Init once
                if (!me.iscroll.segments && me.utilities.viewMode(pageUuid) == 'image') {
                    // iPad and page is zoomable
                    if (me.browser.isIpad() && me.utilities.isZoom()) {

                        // Set image width
                        $segScroller.width(2 * me.utilities.availableWidth());

                        // Scroller options
                        segOptions.scrollbars = false,
                        segOptions.zoomMin = 0.5

                    // iPhone/iPod and zoomable
                    } else if ((me.browser.isIphone() || me.browser.isIpod()) && me.utilities.isZoom()) {

                        // Set image width
                        $segScroller.width(4 * me.utilities.availableWidth());

                        // Scroller options
                        segOptions.scrollbars = false,
                        segOptions.zoomMin = 0.25

                    } else {
                        if (!pageSizingHeight) {
                            if ($('#eedition-segments-container').height() == 0 || $('#eedition-segments-container').width() == 0) {
                                segOptions.zoomMin = 1;
                            } else {
                                segOptions.zoomMin = $('#eedition-segments-container').height() / ($('#eedition-segments-container').width() / aspectRatio);
                            }
                        }
                    }

                    // Allow further zooming if sized by page height and not iOS
                    if (pageSizingHeight && !me.browser.isIos()) {
                        segOptions.zoomMax = 50;
                    }

                    // Hide overflow in image view
                    $('#eedition-segments-container').css('overflow', 'hidden').addClass('eedition-grabbable');
                    $('.eedition-arrow.next').removeClass('text');

                    me.iscroll.segments = new IScroll('#eedition-segments-container', segOptions),
                    iSeg = me.iscroll.segments,
                    bFlick = true

                    // iPad zoom override
                    if (me.browser.isIpad() && me.utilities.isZoom()) {
                        iSeg.zoom(0.5,0,0,0);
                        iSeg.scrollTo(0,0,0);
                        iSeg.refresh();

                        // Disable flick when zoomed
                        iSeg.on('zoomEnd', function () {
                            bFlick = (iSeg.scale > 0.5) ? false : true;
                        });

                    // iPhone/iPod zoom override
                    } else if ((me.browser.isIphone() || me.browser.isIpod()) && me.utilities.isZoom()) {
                        iSeg.zoom(0.25,0,0,0);
                        iSeg.scrollTo(0,0,0);
                        iSeg.refresh();

                        // Disable flick when zoomed
                        iSeg.on('zoomEnd', function () {
                            bFlick = (iSeg.scale > 0.25) ? false : true;
                        });

                    // Desktop
                    } else {

                        // Disable flick when zoomed
                        iSeg.on('zoomEnd', function () {
                            bFlick = (iSeg.scale > 1) ? false : true;
                        });
                    }

                    // Flick events
                    var showThumbs = false;
                    iSeg.on('flickLeft', function () {
                        showThumbs = $('.eedition-bottombar').hasClass('active') ? true : false;
                        if (bFlick) me.navigation.navigate(eeData[$('*[data-eedition]').data('eedition-uuid')].next, true, showThumbs);
                    });
                    iSeg.on('flickRight', function () {
                        showThumbs = $('.eedition-bottombar').hasClass('active') ? true : false;
                        if (bFlick) me.navigation.navigate(eeData[$('*[data-eedition]').data('eedition-uuid')].prev, true, showThumbs);
                    });

                    // Double Click/Tap
                    var tapped = false;
                    iSeg.on('doubleTap', function () {
                        if (!tapped) {
                            tapped = true;
                            setTimeout( function () {
                                tapped = false;
                            }, 1000);
                        } else {
                            clearTimeout(tapped);
                            tapped = false;

                            // iPad
                            if (me.browser.isIpad() && me.utilities.isZoom()) {
                                if (iSeg.scale == 0.5) {
                                    iSeg.zoom(1, iSeg.pointX, iSeg.pointY, 500);
                                } else if (iSeg.scale == 1) {
                                    iSeg.zoom(0.5, iSeg.pointX, iSeg.pointY, 500);
                                }

                            // iPhone/iPod
                            } else if ((me.browser.isIphone() || me.browser.isIpod()) && me.utilities.isZoom()) {
                                if (iSeg.scale == 0.25) {
                                    iSeg.zoom(1, iSeg.pointX, iSeg.pointY, 500);
                                } else if (iSeg.scale == 1) {
                                    iSeg.zoom(0.25, iSeg.pointX, iSeg.pointY, 500);
                                }
                            } else {
                            // Desktop
                                if (iSeg.scale == 1) {
                                    iSeg.zoom(2, iSeg.pointX, iSeg.pointY, 500);
                                } else if (iSeg.scale == 2) {
                                    iSeg.zoom(1, iSeg.pointX, iSeg.pointY, 500);
                                }
                            }
                        }
                    });

                    // Left arrow key event
                    iSeg.on('leftArrow', function () {
                        me.navigation.navigate(eeData[$('*[data-eedition]').data('eedition-uuid')].prev, false, false);
                    });

                    // Right arrow key event
                    iSeg.on('rightArrow', function () {
                        me.navigation.navigate(eeData[$('*[data-eedition]').data('eedition-uuid')].next, false, false);
                    });

                } else if (!me.iscroll.map && me.utilities.viewMode(pageUuid) == 'text') {
                    var showThumbs = false;
                    var mapOptions = {
                        HWCompositing: false,
                        momentum: false,
                        doubleTap: true,
                        freeScroll: true,
                        mouseWheel: true,
                        scrollX: true,
                        scrollY: true,
                        scrollerMargin: pageMargin,
                        tap: true,
                        zoom: true,
                        zoomMax: 25
                    };
                    var $mapScroller = $('#eedition-map-container .eedition-map-scroller');
                    var imageRatio = $('#eedition-image-map').data('image-width') / $('#eedition-image-map').data('image-height');
                    var isPortrait = window.matchMedia('(orientation: portrait)').matches || $(window).width() < $(window).height();

                    if ((me.browser.isIphone() || me.browser.isIpod()) && isPortrait) {
                        // Set scroller width
                        $mapScroller.width(4 * me.utilities.availableWidth());

                        var newWidth = me.utilities.availableWidth() * 4;
                        var newHeight = newWidth / imageRatio;

                        $('#eedition-image-map').width(newWidth).height(newHeight);

                        // Scroller options
                        mapOptions.scrollbars = false;
                        mapOptions.zoomMin = 0.25;

                    } else if (me.browser.isIpad() && isPortrait) {
                        // Set scroller width
                        $mapScroller.width(2 * me.utilities.availableWidth());

                        var newWidth = me.utilities.availableWidth() * 2;
                        var newHeight = newWidth / imageRatio;

                        $('#eedition-image-map').width(newWidth).height(newHeight);

                        // Scroller options
                        mapOptions.scrollbars = false;
                        mapOptions.zoomMin = 0.5;

                    } else {
                        var newHeight = me.utilities.availableHeight();
                        var newWidth = newHeight * imageRatio;

                        if (window.matchMedia('(orientation: landscape)').matches || $(window).width() > $(window).height()) {
                            $mapScroller.width();
                            $mapScroller.height(me.utilities.availableHeight());
                        } else {
                            $mapScroller.width(me.utilities.availableWidth());
                        }

                        $('#eedition-image-map').width(newWidth).height(newHeight);
                        
                        mapOptions.zoomMin = 1;
                    }

                    me.iscroll.map = new IScroll('#eedition-map-container', mapOptions);
                    iMap = me.iscroll.map;

                    if (me.browser.isIphone() || me.browser.isIpod()) {
                        iMap.zoom(0.25,0,0,0);
                        iMap.scrollTo(0,0,0);
                        iMap.refresh();
                    } else if (me.browser.isIpad()) {
                        iMap.zoom(0.5,0,0,0);
                        iMap.scrollTo(0,0,0);
                        iMap.refresh();
                    }
                    
                    bFlick = true;
                    bZooming = false;
                    
                    iMap.on('zoomStart', function() {
                        bZooming = true;
                    });
                    
                    // Disable flick when zoomed
                    iMap.on('zoomEnd', function () {
                        bFlick = (iMap.scale > mapOptions.zoomMin) ? false : true;
                        bZooming = false;
                    });
                    
                    // Flick events
                    var showThumbs = false;
                    iMap.on('flickLeft', function () {
                        showThumbs = $('.eedition-bottombar').hasClass('active') ? true : false;
                        if (bFlick) me.navigation.navigate(eeData[$('*[data-eedition]').data('eedition-uuid')].next, true, showThumbs);
                    });
                    iMap.on('flickRight', function () {
                        showThumbs = $('.eedition-bottombar').hasClass('active') ? true : false;
                        if (bFlick) me.navigation.navigate(eeData[$('*[data-eedition]').data('eedition-uuid')].prev, true, showThumbs);
                    });
                    
                    $('#eedition-segments-container').on('swipeleft', function() {
                        showThumbs = $('.eedition-bottombar').hasClass('active') ? true : false;
                        if (!bZooming) me.navigation.navigate(eeData[$('*[data-eedition]').data('eedition-uuid')].next, true, showThumbs);
                    });

                    $('#eedition-segments-container').on('swiperight', function() {
                        showThumbs = $('.eedition-bottombar').hasClass('active') ? true : false;
                        if (!bZooming) me.navigation.navigate(eeData[$('*[data-eedition]').data('eedition-uuid')].prev, true, showThumbs);
                    });
                }

                // Scroll End
                if ($('#eedition-segments').css('display') !== 'none') {
                    $('#eedition-segments-container').scroll(function() {
                        clearTimeout($.data(this, 'scrollTimer'));
                        $.data(this, 'scrollTimer', setTimeout(function() {
                            $('.segment-article.segments, .segment-image.segments').each(function() {
                                if (me.utilities.isOnScreen(this)) {
                                    var uuid = $(this).data('segmenting').uuid,
                                        action = "scroll";
                                    me.imageMap.selectSegment(uuid, action);
                                    return false;
                                }
                            });
                        }, 250));
                    });
                };

                // Video/YouTube Segment Modals
                var segVideo = '.segment-video .segment-card-headline a, .segment-video .segment-card-image',
                    segVideoChild = '.segment-video-child .segment-card-headline a, .segment-video-child .segment-card-image',
                    segYouTube = '.segment-youtube .segment-card-headline a, .segment-youtube .segment-card-image',
                    segYouTubeChild = '.segment-youtube-child .segment-card-headline a, .segment-youtube-child .segment-card-image',
                    segVideoYouTube = [segVideo, segYouTube, segVideoChild, segYouTubeChild].join(',');

                $(segVideoYouTube).on('click', function (e) {
                    e.preventDefault();                        
                    $('#modal-' + $(this).data('uuid')).modal();
                });

                if ($('.eedition-bottombar-thumbnails').length > 0) {
                    me.iscroll.pages = new IScroll('.eedition-bottombar-thumbnails', pageOptions);
                    iThumb = me.iscroll.pages;
                    iThumb.scrollToElement("#eedition-page-" + pageUuid, '0');
                }

                if ($('.eedition-bottombar-sections').length > 0) {
                    me.iscroll.sections = new IScroll('.eedition-bottombar-sections', pageOptions);
                }

                $('.segment-areas, .ron-segment-cutout').click(function(e) {
                    e.preventDefault();
                });

                // Focus on segment based on image map and RoN
                if (!tapDefined) {
                    $('.segment-areas, .ron-segment-cutout').on('tap click', function(e) {
                        if ($(this).data('area').type === 'cutout' || $(this).data('area').type === 'youtube' || $(this).data('area').type === 'video') {
                            var segmentUuid = $(this).data('area').uuid;
                            $('#modal-' + segmentUuid).modal();
                            __tnt.eedition.cutouts.drawImage(segmentUuid);
                            if ($(window).width() > 1024) {
                                $(".segment-cutout-area.panel-closed .cutout-panel-toggle").click();
                            }
                        } else {
                            var timeout = 0;
                            if ($('#eedition-page-container').hasClass('mode-map')) {
                                setTimeout(function() {
                                    $('#eedition-page-container').removeClass('mode-map').addClass('mode-seg');
                                    $('.eedition-text-size').show();
                                    $('#eedition-clip').hide();
                                    me.utilities.positionBottombarButton();
                                }, 100);
                                timeout = 250;
                            }
                            var that = this;
                            setTimeout(function() {
                                var segmentDiv = '#segment-dialog-' + $(that).data('area').uuid,
                                    position = $(segmentDiv).position().top + $("#eedition-segments-container").scrollTop();
                                $('#eedition-segments-container')[0].scrollTop = position;
                            }, timeout);
                        }
                    });
                    tapDefined = true;
                }

                $('#eedition-back-to-map').click(function() {
                    $('#eedition-page-container').removeClass('mode-seg').addClass('mode-map');
                    $('.eedition-text-size').hide();
                    $('#eedition-clip').show();
                    me.utilities.positionBottombarButton();
                });
            }
        },

        preInit: function() {
            var img = $('.image-plain'),
                initScroll = false,
                loader = $('.eedition-loader'),
                viewMode = me.utilities.viewMode($('#eedition-data').data('eedition-uuid'));
            if (eeData[pageUuid].segments && viewMode == 'text') {
                me.resize();
                me.iscroll.init();
            } else {
                me.resize();
                loader.show();
                loadImage(img).then(function() {
                    loader.hide();
                    $(img).fadeIn(500).css('display','block');
                    if (!initScroll) {
                        me.iscroll.init();
                        initScroll = true;
                    } else {
                        me.iscroll.segments.refresh();
                    }
                });
            }
        },

        refreshAll: function() {
            if (me.iscroll.segments) {
                me.iscroll.segments.refresh();
            }
            if (me.iscroll.map) {
                me.iscroll.map.refresh();
            }
            if ($('.eedition-thumbnail-scroller').length > 0) {
                $('.eedition-thumbnail-scroller').width($('.eedition-thumbnail-scroller ul')[0].scrollWidth);
                if (me.iscroll.pages) {
                    me.iscroll.pages.refresh();
                }
            }
            if ($('.eedition-section-scroller').length > 0) {
                $('.eedition-section-scroller').width($('.eedition-section-scroller ul')[0].scrollWidth);
                if (me.iscroll.sections) {
                    me.iscroll.sections.refresh();
                }
            }
        }
    };

})(window, document, jQuery, __tnt);